import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/ryan.parr/Documents/Apps/sprinklr.design/src/templates/mdx-page.js";
import Foo from '../../components/Docs/Foo.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3>{`why space`}</h3>
    <p>{`With an aim to give different users their own personal environment, we have named our design system Space. It is an adaptable and reusable system used to assemble the Sprinklr platform which has been designed to be personalised for each user to achieve their individual tasks and goals. It also helps maximise efficiency and productivity for our design teams. Following are the principles that arch the guidelines:`}</p>
    <h3>{`the sprinklr platform`}</h3>
    <p>{`At Sprinklr, we are re-imagining a new class of enterprise software under the umbrella of `}<inlineCode parentName="p">{`Customer Experience Platform (CXM)`}</inlineCode>{` which is a culmination of five of our core offerings i.e Marketing, Advertising, Research, Commerce and Care, all of which work together synchronously to help brands create best experience for their customers.`}</p>
    <h3>{`our design system principles`}</h3>
    {/*
     ### distributed
     We empower every designer to give their best and collaborate efficiently. A distributed ownership helps keep the system easily understandable and truly adaptable for others use.
     ### modular
     We inherit the scale of the platform and craft even the smallest components with precision. Every part can then be reused and works fluently in all situations and contexts.
     ### robust
     On our way to designing the world’s most loved enterprise product — we iterate, we fumble, we fail; and we adhere to an agile process which makes it mandatory for our design system to be robust.
     ### scalable
     As a team, we strive to innovate. The philosophy that guides every decision is — when we grow, we can proudly hone our journey; and not always start from scratch. That way, our system is scalable.
     */}
    <Foo title="distributed" icon={3} mdxType="Foo">
  We empower every designer to give their best and collaborate efficiently. A
  distributed ownership helps keep the system easily understandable and truly
  adaptable for others use.
    </Foo>
    <Foo title="modular" icon={4} mdxType="Foo">
  We inherit the scale of the platform and craft even the smallest components
  with precision. Every part can then be reused and works fluently in all
  situations and contexts.
    </Foo>
    <Foo title="robust" icon={2} mdxType="Foo">
  On our way to designing the world’s most loved enterprise product — we
  iterate, we fumble, we fail; and we adhere to an agile process which makes it
  mandatory for our design system to be robust.
    </Foo>
    <Foo title="scalable" icon={1} mdxType="Foo">
  As a team, we strive to innovate. The philosophy that guides every decision is
  — when we grow, we can proudly hone our journey; and not always start from
  scratch. That way, our system is scalable.
    </Foo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      